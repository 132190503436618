export const DAYS_PER_YEAR = 365;
export const DAYS_PER_MONTH = 31;
export const DAYS_PER_WEEK = 7;
export const DAYS_PER_TWO_MONTH = 62;
export const DAYS_PER_THREE_MONTH = 90;
export const DAYS_PER_FOUR_MONTH = 121;
export const DAYS_PER_FIVE_MONTH = 152;
export const DAYS_PER_SIX_MONTH = 182;
export const DAYS_PER_SEVEN_MONTH = 212;
export const DAYS_PER_EIGHT_MONTH = 243;
export const DAYS_PER_NINE_MONTH = 273;
export const DAYS_PER_TEN_MONTH = 304;
export const DAYS_PER_ELEVEN_MONTH = 334;

// product codes with full access
export const APP_FULL_ACCESS = 'app_full_access';
export const APP_PLAN = 'plan';

export const APP_GUIDE_ALL = 'fat_burning_gd__weight_loss_gd';
export const APP_GUIDE_ALL_2 = 'fat_burning_gd__weight_loss_gd_2';
export const APP_GUIDE_ALL_3 = 'fat_burning_gd__weight_loss_gd_3';
export const APP_GUIDE_WEIGHT_LOSS = 'weight_loss_gd';
export const APP_GUIDE_FAT_BURNING = 'fat_burning_gd';
export const APP_VIP_SUPPORT = 'vip_support';
export const APP_MEAL_PLAN = 'meal_plan';
export const APP_WELLCUBE = 'wellcube_subs';
export const VIDEO_COURSE_FACELIFT = 'video_course_facelift';
export const APP_ABS = 'abs';
export const APP_LIBIDO = 'libido';
export const APP_FASTING = 'fasting';
export const APP_WALLPILATES = 'wallpilates';
export const APP_ABS_LIBIDO = 'abs_libido';
export const APP_FASTING_WALLPILATES = 'fasting_wallpilates';
export const APP_FASTING_LIBIDO = 'fasting_libido';
export const APP_COACHING = 'coaching';

export const WELLCUBE_SUBSCRIPTION_DISCOUNT = 'One-month intro (19.99) + One-month (19.99)';
export const WELLCUBE_SUBSCRIPTION_FULL = 'Three-months intro (39.99) - Three-months (39.99)';

export const CREDIT_CARD = 'CREDIT_CARD';
export const PAYPAL = 'PAYPAL';

export const PAYMENT_TYPES = {
    CREDIT_CARD: 1,
    PAYPAL: 4,
    APPLE: 1,
    GOOGLE: 1,
};

export const PAYMENT_TYPES_NAME = {
    CREDIT_CARD: 'Bank_Card',
    PAYPAL: 'PayPal',
    APPLE: 'apple_pay',
    GOOGLE: 'google_pay',
};

export const PAYMENT_TYPES_NAME_ID = {
    CREDIT_CARD: 'bankCard',
    PAYPAL: 'payPal',
    APPLE: 'applePay',
    GOOGLE: 'paymentGenerator',
};

export const PAYMENT_DEFAULT_CURRENCY = {
    sign: '$',
    name: 'USD',
};

export const EURO = {
    sign: '€',
    name: 'EUR',
};

export const GBP = {
    sign: '£',
    name: 'GBP',
};

export const BRL = {
    sign: 'R$',
    name: 'BRL',
};

export const MXN = {
    sign: 'MEX$',
    name: 'MXN',
};

export const DEVICE_OS = {
    ANDROID: 'ANDROID',
    IOS: 'IOS',
    MAC_OS: 'MAC OS',
    DEFAULT: 'DEFAULT_DEVICE',
};

export const PAYMENT_FLOW_PERIODS = [
    DAYS_PER_MONTH,
    DAYS_PER_TWO_MONTH,
    DAYS_PER_THREE_MONTH,
    DAYS_PER_FOUR_MONTH,
    DAYS_PER_FIVE_MONTH,
    DAYS_PER_SIX_MONTH,
    DAYS_PER_SEVEN_MONTH,
    DAYS_PER_EIGHT_MONTH,
    DAYS_PER_YEAR,
    DAYS_PER_WEEK,
];

export const PRODUCT_CODES = [
    APP_FULL_ACCESS,
    APP_PLAN,
    APP_GUIDE_ALL,
    APP_GUIDE_ALL_2,
    APP_GUIDE_ALL_3,
    APP_GUIDE_WEIGHT_LOSS,
    APP_GUIDE_FAT_BURNING,
    APP_VIP_SUPPORT,
    APP_MEAL_PLAN,
    WELLCUBE_SUBSCRIPTION_DISCOUNT,
    WELLCUBE_SUBSCRIPTION_FULL,
    APP_WELLCUBE,
    APP_ABS,
    APP_LIBIDO,
    APP_FASTING,
    APP_WALLPILATES,
    APP_ABS_LIBIDO,
    APP_FASTING_WALLPILATES,
    APP_FASTING_LIBIDO,
    APP_COACHING,
] as const;

export const PAYMENT_PURCHASE_ERROR = 'Conflict Request';

export const LTV_COEFFICIENT_KEY = 'ltv_coef';
export const DEFAULT_LTV_COEFFICIENT = 1;
