export const onboardingScreensWithoutProgressBar = [
    'ob-social-proof',
    'ob-social-proof-over',
    'ob-social-proof-ages',
    'social-proof-full-conf',
    'creating-b',
    'result-a',
    'result-a-conf',
    'feedback-summary-fitlevel',
    'email-consent',
    'email-consent-a',
    'email-consent-a-conf',
    'email-account-e',
    'email-account-f',
    'creating-data-comp',
    'ob-data-comp',
    'ob-feedback-goal',
    'ob-feedback-streacks',
    'ob-feedback-effort',
    'ob-experts',
];
